module.exports = [{
      plugin: require('../node_modules/@newrelic/gatsby-theme-newrelic/gatsby-browser.js'),
      options: {"plugins":[],"layout":{"maxWidth":"1236px","contentPadding":"28px","mobileBreakpoint":"760px"},"newrelic":{"config":{"instrumentationType":"proAndSPA","accountId":"10956800","trustKey":"1","agentID":"30713728","licenseKey":"NRJS-649173eb1a7b28cd6ab","applicationID":"30713728","beacon":"staging-bam-cell.nr-data.net","errorBeacon":"staging-bam-cell.nr-data.net"}},"segment":{"segmentWriteKey":"noviNOFjASOSPcSEAkwoRxOt0Y1719KD","section":"opensource","platform":"opensource_pages"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"New Relic Open source","short_name":"NR OSS","start_url":"/","background_color":"#007e8a","theme_color":"#007e8a","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c6adf0dad65cc1d73c93c5e0b1680678"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":590}}],"extensions":[".mdx",".md"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["Referrer-Policy: no-referrer-when-downgrade"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
