exports.components = {
  "component---node-modules-newrelic-gatsby-theme-newrelic-src-pages-404-js": () => import("./../../../node_modules/@newrelic/gatsby-theme-newrelic/src/pages/404.js" /* webpackChunkName: "component---node-modules-newrelic-gatsby-theme-newrelic-src-pages-404-js" */),
  "component---src-pages-cla-mdx": () => import("./../../../src/pages/cla.mdx" /* webpackChunkName: "component---src-pages-cla-mdx" */),
  "component---src-pages-code-of-conduct-mdx": () => import("./../../../src/pages/code-of-conduct.mdx" /* webpackChunkName: "component---src-pages-code-of-conduct-mdx" */),
  "component---src-pages-code-of-conduct-reporting-mdx": () => import("./../../../src/pages/code-of-conduct-reporting.mdx" /* webpackChunkName: "component---src-pages-code-of-conduct-reporting-mdx" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contributing-mdx": () => import("./../../../src/pages/contributing.mdx" /* webpackChunkName: "component---src-pages-contributing-mdx" */),
  "component---src-pages-explore-projects-js": () => import("./../../../src/pages/explore-projects.js" /* webpackChunkName: "component---src-pages-explore-projects-js" */),
  "component---src-pages-external-projects-js": () => import("./../../../src/pages/external-projects.js" /* webpackChunkName: "component---src-pages-external-projects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instrumentation-js": () => import("./../../../src/pages/instrumentation.js" /* webpackChunkName: "component---src-pages-instrumentation-js" */),
  "component---src-pages-layouts-js": () => import("./../../../src/pages/layouts.js" /* webpackChunkName: "component---src-pages-layouts-js" */),
  "component---src-pages-nerdpacks-js": () => import("./../../../src/pages/nerdpacks.js" /* webpackChunkName: "component---src-pages-nerdpacks-js" */),
  "component---src-pages-oss-category-js": () => import("./../../../src/pages/oss-category.js" /* webpackChunkName: "component---src-pages-oss-category-js" */),
  "component---src-pages-standards-mdx": () => import("./../../../src/pages/standards.mdx" /* webpackChunkName: "component---src-pages-standards-mdx" */),
  "component---src-templates-external-project-page-js": () => import("./../../../src/templates/external-project-page.js" /* webpackChunkName: "component---src-templates-external-project-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */)
}

